import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { TransitionProps } from "@mui/material/transitions";
import { useIntl } from "react-intl";
import { Venue } from "../../types/venue";
import { GiftcardAccount } from "../../types/account";
import { centsToEuros } from "../../utils/amount/toEuros";
import Divider from "@mui/material/Divider";
import {
  Box,
  Slide,
  SlideProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Close } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useGlobalStyles } from "../../muiTheme/styles";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiDialog-scrollPaper": {
      [theme.breakpoints.only("md")]: {
        alignItems: "flex-end",
      },
    },
    "& .MuiDialog-paperWidthXl": {
      [theme.breakpoints.up("lg")]: {
        minWidth: "700px",
      },
    },
    "& .MuiDialog-paperFullScreen": {
      [theme.breakpoints.up("md")]: {
        height: "auto",
      },
    },
  },
  cancelButton: {
    border: "none",
    backgroundColor: "transparent",
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
    },
  },
  confirmButton: {
    maxWidth: "340px",
    width: "100%",
    margin: "auto",
  },
  text: {
    fontSize: "1.125rem",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...(props as SlideProps)} />;
});

type GiftcardPaymentProps = {
  payment: { amount: string; email: string };
  venue: Venue;
  giftcardAccount: GiftcardAccount;
  handleClose: () => void;
  handlePaymentConfirm: () => void;
};

export default function PaymentConfirmDialog({
  payment,
  handlePaymentConfirm,
  handleClose,
  venue,
  giftcardAccount,
}: GiftcardPaymentProps) {
  const intl = useIntl();
  const { classes } = useStyles();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const handleConfirm = () => {
    handlePaymentConfirm();
    handleClose();
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={!matches}
      maxWidth="xl"
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Grid container direction="row" justifyContent="space-between">
        <Box />
        <Box mt={6} mb={6}>
          <Typography variant="h5">
            <b>
              {intl.formatMessage({
                id: "PaymentConfirmDialog.confirmPayment",
              })}
            </b>
          </Typography>
        </Box>
        <Box mt={5}>
          <Close onClick={handleClose} fontSize="large" />
        </Box>
      </Grid>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          className={globalClasses.semiBold}
        >
          {intl.formatMessage({ id: "PaymentConfirmDialog.receiver" })}:
        </Typography>
        <Box mt={2} />
        <Typography variant="h5" className={classes.text}>
          {venue.name}
        </Typography>

        <Typography variant="body1">
          {venue.address.line1}, {venue.address.postal_code}{" "}
          {venue.address.city}
        </Typography>
        <Box mt={4} />
        <Divider />
        <Box mt={4} />
        <Typography
          variant="body1"
          color="textSecondary"
          className={globalClasses.semiBold}
        >
          {intl.formatMessage({ id: "PaymentConfirmDialog.amount" })}:
        </Typography>
        <Box mt={4} />

        <Typography variant="h5" className={classes.text}>
          {payment.amount}
        </Typography>
        <Box mt={4} />
        <Divider />
        <Box mt={4} />
        <Typography
          variant="body1"
          color="textSecondary"
          className={globalClasses.semiBold}
        >
          {intl.formatMessage({ id: "PaymentMethod.paymentMethod" })}:
        </Typography>
        <Box mt={4} />

        <Typography variant="h5" className={classes.text}>
          {intl.formatMessage({ id: `giftcards.${giftcardAccount.name}` })}{" "}
          {centsToEuros(giftcardAccount.balance)}
        </Typography>
        <Box mt={4} />
        <Divider />
        <Box mt={4} />
        <Typography
          variant="body1"
          color="textSecondary"
          className={globalClasses.semiBold}
        >
          {intl.formatMessage({ id: "PaymentMethod.email" })}:
        </Typography>
        <Typography variant="h5" className={classes.text}>
          {payment.email}
        </Typography>
        <Box mt={4} />

        <Divider />
      </DialogContent>
      <Grid container direction="column">
        <Box mt={5} />
        <Button onClick={handleConfirm} className={classes.confirmButton}>
          {intl.formatMessage({ id: "PaymentConfirmDialog.confirmPayment" })}
        </Button>
        <Box mt={5} />

        <Button onClick={handleClose} className={classes.cancelButton}>
          {intl.formatMessage({ id: "PaymentMethod.cancel" })}
        </Button>
      </Grid>
    </Dialog>
  );
}
