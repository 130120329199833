export default {
  VenueSearch: {
    header: "Search venues",
    subtitle: "Find a venue and pay with a Smartum gift card or Smartum benefits.",
    subtitle1:
      "Discover interesting venues from nearly 40,000 service providers in the Smartum network.",
    subtitle2:
      "A new payment feature has been added to the search! You can now easily make payments with Smartum gift cards and Smartum benefits to your desired venue.",
    howToMakePayment: "How to make a payment.",
    howToMakePaymentLink: "https://www.smartum.fi/knowledge/smartum-lahjakortilla-maksaminen",
    inputPlaceholder: "Search by name, benefit type, city..",
    noResults: "No results were found for your search.",
    showResults: "Show results",
    searchQuery: 'Search results for "{query}"',
    searchResultPlaceholder1: "Discover new experiences.",
    searchResultPlaceholder2: "Nearly 40,000 venues.",
    searchResultPlaceholder3: "Dozens of new venues added every month.",
    allResults: "All results",
    filterResults: "Filter results",
    benefitTypes: "Search results",
    loadMore: "Load more",
    numberOfVenues: "Showing {number} first search results",
    error: {
      fetch_failed: "Fetching venues failed. Try again later.",
    },
  },
  VenueItem: {
    paymentMethods: "Payment methods",
    acceptsPayment: "Accepts",
    onlinePayment: "Pay directly on the provider's website.",
    makePayment: "Make Payment",
    loadingVenueInformation: "Loading venue information...",
    showOnMap: "Show on map", 
    benefitName: "{benefit} benefit",
    paymentMethod: {
      voucher: "Smartum-voucher",
      mobile: "SmartumPay",
      sms: "Send text message TOIMIPISTETUNNUS SUMMA to {phoneNumber} to pay.",
      giftcard: "Giftcard",

    }
  },
  Receipt: {
    thankYouTitle: "Thank you!",
    receiptTitle: "Your receipt",
    description: "Show this receipt at the cashier. ",
    receiptDisclaimer: "Note! The receipt is valid for 30 minutes from the time of purchase. You will also receive the payment receipt by email.",
    verificationButtonVerify: "Mark as checked",
    verificationVerified: "Verified",
    paymentType: "Payment type: ", 
    payer: "Payer",
    qrCode: "QR code",
    giftcardPayment: "Giftcardpayment",
  },
  PaymentConfirmDialog: {
    confirmPayment: "Confirm payment",
    receiver: "Recipient",
    amount: "Payment amount",
  },
  AmountInput: {
    amount: "Payment amount",    
    enterAmount: "Enter amount",
    error: {
      missingAmount: "Enter an amount to continue to pay.",
    },
  },
  PaymentSelect: {
    error: {
      paymentMethodNotSelected: "Select a payment method to continue to pay.",
      giftardUsageLimitationsInfo: "The gift card cannot be used for lunch or commute payments.",
    },
    lunchPaymentInfo: "The amount must be between {min} - {max}.",
    continue: "Continue to payment",
    paymentMethods: "Payment methods",
    paymentDisclaimer: "Note! Make the payment at the venue's cashier. The receipt is valid for 30 minutes after payment and must be shown at the cashier.",
    makePaymentToVenue: "Make payment to the venue"
  },
  PaymentMethod: {
    paymentMethod: "Payment method",
    selectPaymentMethod: "Choose payment method",
    benefit: "Smartum benefit",
    email: "Email",
    beneficiaryPayment: "Pay with Smartum benefit",
    giftcardPayment: "Pay with Smartum gift card", 
    cancel: "Cancel",
    selectBenefitType: "Choose benefit type:",
    benefitType: "Smartum {benefit} benefit"
  },
  buttons: {
    OK: "OK",
    add: "Add",
  },
  GiftcardInput: {
    error: {
      parameter_invalid: "The code must be 9 characters long.",
      missing_claimcode: "Add the gift card code",
      account_not_found:
        "No gift card found with the code. Please make sure you have entered the code correctly.",
      invalid_benefit: "The gift card you entered cannot be used for payment at this venue. Accepted payment methods: Culture benefit, Smartum Gift Card, Smartum Culture Gift Card.",
      invalid_amount: "The value of the gift card is insufficient to cover the selected amount.",
      expired: "This gift card is no longer valid.",
      generic: "Payment details are missing. Please fill in the missing information to continue.",
      missing_email: "Enter your email address to continue to pay.",
      invalid_email:  "Invalid email.",

    },
    email: "Email address",
    claimCode: "Add gift card code",
    giftcardInputDisclaimer: "Note! If you are paying with OwnGiftCard that you purchased with your expiring benefit, select 'I am paying with Smartum benefit.'",
    giftcardReceiptInfo: "Lähetämme kuitin maksusta myös sähköpostiisi. ",
    giftcardRemainingBalance: "Balance"
  },
  giftcards: {
    wellbeing: "Smartum Wellbeing giftcard",
    exercise: "Smartum Exercise giftcard",
    culture: "Smartum Culture giftcard",
    generic: "Smartum giftcard",
  },
  AppHeader: {
    language: {
      english: "English",
      finnish: "Finnish",
    },
  },
  AccountSection: {
    title: {
      general: "Pay with Smartum",
      payAmountWithSmartum: "Pay {price}€ with Smartum",
    },
    buttonDefaultText: "Pay {price}",
    buttonRunningText: "Running...",
    noUsableAccount:
      "Oops! Unfortunately you don’t have the right benefit saldo for this purchase.",
  },
  StripeSection: {
    title: {
      payAmountWithCard: "Pay {price}€ with Card",
    },
    subtitle: {
      minimumCharge: "Card payments have a min charge of 50 cents.",
    },
  },
  benefits: {
    exerciseAndCulture: "Exercise & culture",
    commuter: "Commuter",
    massage: "Massage",
    lunch: "Lunch",
    exercise: "Exercise",
    culture: "Culture",
    health: "Health",
    Plus: "SmartumPlus",
  },
  Modal: {
    confirmMessage: {
      success: "Payment successful!",
      error: {
        general: "Payment was unsuccessful.",
        contract_required: "Payment was unsuccessful.",
        employer_inactive:
          "Benefit is shared by inactive employer. Payments can't be done from these kinds of benefits. Please contact your employer.",
        benefit_type_invalid:
          "It seems that You don't have an equivalent benefit for this payment in use. You can check your own benefits from the Benefits page. In case of any problems, please contact our customer service.",
        yearly_limit_exceeded:
          "We're sorry, but it looks like you have reached your yearly limit for this benefit type. You can read more about the limits set by the Tax Administration on their site.",
        account_not_activated:
          "This benefit account has not been activated yet.",
        account_expired: "This benefit account has expired.",
        payment_amount_zero: "Payment must be higher than zero.",
        lunch_limit_exceeded: "Maximum lunch amount exceeded.",
        lunch_limit_subceeded: "Minimum lunch amount must be met.",
        timeout: "Oh dear. The time for the payment is up.",
        insufficient_funds:
          "Oh dear. There's not enough balance in your account.",
        unauthorized: "Unauthorized Attempts...",
      },
    },
    redirectMessage:
      "Returning to webstore, please wait and don't close the browser.",
  },
  AppContent: {
    timeRemaining: "{time} time left",
    navigationInstruction:
      "Please wait, you’re being transferred back to the webshop. Don’t close the browser or use the back button.",
  },
  AppFooter: {
    cancelText: "Cancel",
    helpCenterText: "Help Center",
  },
  VenueSearchFooter: {
    companyInfo: {
      companyName: "Smartum Oy",
      companyAddress: "Yrjönkatu 11 B",
      companyPostalCode: "00120 Helsinki",
      companyIdentifier: "Y-Tunnus: 2780101-9",
    },
    products: {
      sectionTitle: "Products",
      purchaseGiftcard: "Purchase Smartum Gift Card",
      purchaseGiftcardLink: "https://kauppa.smartum.fi",
      useGiftcard: "Use Smartum Gift Card",
      useGiftcardLink: "https://pay.smartum.fi/",
      employeeBenefits: "Employee Benefits",
      employeeBenefitsLink: "https://www.smartum.fi/",
    },
    help: {
      heading: "Need Help?",
      faq: "Frequently Asked Questions",
      faqLink: "https://kauppa.smartum.fi/pages/contact",
      helpCenter: "Help Center",
      helpCenterLink: "https://www.smartum.fi/knowledge/smartum-lahjakortti",
    },
    footer: {
      copyrightText: "© 2024 Smartum Oy",
      rightsReserved: "All rights reserved",
      privacyPolicy: "Privacy Policy",
      privacyPolicyLink: "https://www.smartum.fi/tietosuojaselosteet",
      cookies: "Cookies",
      cookiesLink: "https://www.smartum.fi/evasteiden-kaytto",
      facebook: "https://fi-fi.facebook.com/smartumfi",
      instagram: "https://www.instagram.com/smartumfi/",
    }
  },
  ErrorPage: {
    paymentFailed: {
      title: "Payment failed",
      message: "Sorry, there was an error in confirming the payment.",
    }, 
    receiptDownloadFailed: {
      title: "Failed to load receipt...",
      message: "We apologize, there was an error in downloading the receipt. We have also sent the payment receipt to your email.",
    }, 
    pageNotFound: {
      title: "Page not found...",
      message: "Page not found or something went wrong while loading the page. Return to the ",
      linkTitle: "homepage.",
    },
  },
  GenericError: {
    general: {
      text: {
        heading: "Page not found",
        explanation:
          "This page might have been removed or it might be temporarily unavailable.",
      },
    },
    internalError: {
      text: {
        heading: "Oops! Something went wrong...",
        explanation:
          "We are having technical difficulties and our space technicians have been notified.",
      },
    },
  },
  ApiError: {
    venue_parameter_invalid: "Venue ID is not valid",
    venue_not_found: "Venue is not found",
    account_id_invalid: "Account ID is not valid",
    incompatible_account_id: "Account ID is not valid",
    account_not_found: "Account is not found",
    general: "Try again later",
  },
};
