export default {
  VenueSearch: {
    header: "Hae käyttöpaikkoja",
    subtitle: "Hae käyttöpaikka ja maksa Smartumin lahjakortilla tai työsuhde-edulla.",
    subtitle1:
      "Löydä kiinnostavia palveluita Smartumin lähes 40 000 käyttöpaikan verkostosta. ",
    subtitle2:
      "Uusi maksuominaisuus lisätty käyttöpaikkahakuun! Voit nyt tehdä maksuja Smartum Lahjakorteilla sekä työsuhde-eduilla kätevästi haluamaasi toimipaikkaan.",
    howToMakePayment: "Katso ohjeet maksun tekemiseen.",
    howToMakePaymentLink: "https://www.smartum.fi/knowledge/smartum-lahjakortilla-maksaminen",
    inputPlaceholder: "Hae nimellä, etutyypillä, paikkakunnalla...",
    noResults: "Haullasi ei löytynyt yhtään tulosta.",
    showResults: "Näytä tulokset",
    searchQuery: 'Tulokset haulle "{query}"',
    searchResultPlaceholder1: "Löydä uusia elämyksiä.",
    searchResultPlaceholder2: "Lähes 40 000 käyttökohdetta.",
    searchResultPlaceholder3: "Kymmenittäin uusia kohteita joka kuukausi.",
    allResults: "Kaikki tulokset",
    filterResults: "Rajaa hakua",
    benefitTypes: "Hakutulokset",
    loadMore: "Lataa lisää",
    numberOfVenues: "Näytetään {number} ensimmäistä hakutulosta",
    error: {
      fetch_failed: "Käyttöpaikkojen haku epäonnistui. Yritä myöhemmin uudelleen.",
    }
  },
  VenueItem: {
    paymentMethods: "Maksuvaihtoehdot",
    acceptsPayment: "Vastaanottaa",
    onlinePayment: "Maksa suoraan toimipaikan verkkokaupassa.",
    makePayment: "Tee maksu",
    loadingVenueInformation: "Loading venue information...",
    showOnMap: "Näytä kartalla", 
    benefitName: "{benefit}-etu",
    paymentMethod: {
      voucher: "Smartum-seteli",
      mobile: "SmartumPay",
      sms: "Lähetä viesti TOIMIPISTETUNNUS SUMMA numeroon {phoneNumber} maksaaksesi.",
      giftcard: "Lahjakortti",
    }
  },
  Receipt: {
    thankYouTitle: "Kiitos maksusta!",
    receiptTitle: "Kuittisi maksusta",
    description: "Näytä tämä kuitti toimipaikan kassalla.",
    receiptDisclaimer: "Huom! Kuitti on voimassa 30 minuuttia ostohetkestä. Saat kuitin maksusta myös sähköpostiisi.",
    verificationButtonVerify: "Merkitse tarkastetuksi",
    verificationVerified: "Tarkistettu",
    paymentType: "Maksun tyyppi: ", 
    payer: "Maksaja",
    qrCode: "QR-koodi",
    giftcardPayment: "Lahjakorttimaksu",
  },
  PaymentConfirmDialog: {
    confirmPayment: "Vahvista maksu",
    receiver: "Vastaanottaja",
    amount: "Maksun summa",
  },
  AmountInput: {
    amount: "Maksun summa",
    enterAmount: "Syötä summa",
    error: {
      missingAmount: "Lisää summa jatkaaksesi maksamaan. "
    },
  },
  PaymentSelect: {
    error: {
      paymentMethodNotSelected: "Valitse maksutapa jatkaaksesi maksamaan.",
      giftCardUsageLimitationsInfo: "Lahjakortilla ei voi maksaa lounas- tai työmatkapalveluita.",
    },
    lunchPaymentInfo: "Lounasmaksun tulee olla {min} - {max}.",
    continue: "Jatka maksamaan",
    paymentMethods: "Maksutavat",
    paymentDisclaimer: "Huom! Suorita maksu vasta toimipaikan kassalla. Kuitti on voimassa 30min maksun suorittamisen jälkeen ja näytettävä kassalla.",
    makePaymentToVenue: "Tee maksu toimipaikkaan"
  },
  PaymentMethod: {
    paymentMethod: "Maksutapa",
    selectPaymentMethod: "Valitse maksutapa",
    benefit: "Smartum-etu",
    email: "Sähköposti",
    beneficiaryPayment: "Maksan Smartum työsuhde-edulla",
    giftcardPayment: "Maksan Smartumin lahjakortilla",
    cancel: "Peruuta",
    selectBenefitType: "Valitse käytettävä etu",
    benefitType: "Smartum {benefit}-etu"
  },
  buttons: {
    OK: "OK",
    add: "Lisää",
  },
  GiftcardInput: {
    error: {
      parameter_invalid: "Koodissa tulee olla 9 merkkiä",
      missing_claimcode: "Lisää lahjakortin koodi",
      account_not_found:
        "Koodilla ei löytynyt lahjakorttia. Varmista että olet kirjoittanut koodin oikein.",
      invalid_benefit: "Syöttämälläsi lahjakortilla ei voi maksaa tähän toimipaikkaan. Hyväksytyt maksutavat: { values }.",
      invalid_partner_benefit: "Syöttämälläsi lahjakortilla ei voi maksaa tähän toimipaikkaan.",
      invalid_amount: "Lahjakortin arvo ei riitä valitun summan maksamiseen.",
      expired: "Tämä lahjakortti ei ole enää voimassa.",
      generic: "Maksusta puuttuu tietoja. Täytä puuttuvat tiedot jatkaaksesi.",
      missing_email: "Lisää sähköpostiosoite jatkaaksesi maksamaan.",
      invalid_email:  "Virheellinen sähköposti",
    },
    claimCode: "Lisää lahjakortin koodi",
    email: "Sähköpostiosoite",
    giftcardInputDisclaimer: "Huom! Jos maksat OmaLahjakortilla, jonka olet ostanut vanhenevalla edullasi, valitse ‘Maksan Smartum työsuhde-edulla’.",
    giftcardReceiptInfo: "Lähetämme kuitin maksusta myös sähköpostiisi. ",
    giftcardRemainingBalance: "Arvoa jäljellä"
  },
  giftcards: {
    wellbeing: "Smartum Hieronta & Terveys -lahjakortti",
    exercise: "Smartum Liikunta -lahjakortti",
    culture: "Smartum Kulttuuri -lahjakortti",
    generic: "Smartum Hyvinvointi -lahjakortti",
  },
  AppHeader: {
    language: {
      english: "Englanti",
      finnish: "Suomi",
    },
  },
  AccountSection: {
    title: {
      general: "Maksa Smartumilla",
      payAmountWithSmartum: "Maksa {price}€ Smartumilla",
    },
    buttonDefaultText: "Maksa {price}",
    buttonRunningText: "Suoritetaan...",
    noUsableAccount:
      "Hups! Valitettavasti sinulla ei ole käytössäsi tähän ostokseen soveltuvaa saldoa.",
  },
  StripeSection: {
    title: {
      payAmountWithCard: "Maksa {price}€ kortilla",
    },
    subtitle: {
      minimumCharge: "Korttimaksut ovat vähintään 50 senttiä.",
    },
  },
  benefits: {
    exerciseAndCulture: "Liikunta & kulttuuri",
    commuter: "Työmatka",
    exercise: "Liikunta",
    culture: "Kulttuuri",
    health: "Terveys",
    massage: "Hieronta",
    lunch: "Lounas",
    Plus: "SmartumPlus",
  },
  Modal: {
    confirmMessage: {
      success: "Maksu onnistui!",
      error: {
        general: "Maksu epäonnistui.",
        contract_required: "Maksu epäonnistui.",
        employer_inactive:
          "Edun jakanut yritys ei ole tällä hetkellä aktiivinen. Maksuja ei voi suorittaa tällaisista eduista. Ole yhteydessä yritykseen, joka on jakanut etuja.",
        benefit_type_invalid:
          "Näyttää siltä, että sinulla ei ole maksua vastaavaa etua käytössäsi. Voit tarkistaa omat etusi Edut-sivulta. Ongelmatilanteessa olethan yhteydessä asiakaspalveluumme.",
        yearly_limit_exceeded:
          "Emme voineet suorittaa maksua, sillä se ylittäisi verottajan asettaman vuosittaisen käyttörajan tälle edulle. Voit lukea lisää verottajan asettamista rajoituksista Verohallinnon sivuilta.",
        account_not_activated: "Etutiliä ei ole vielä aktivoitu.",
        account_expired: "Etutili on vanhentunut.",
        payment_amount_zero: "Maksun pitää olla enemmän kuin nolla.",
        lunch_limit_exceeded: "Lounasmaksun enimmäisraja ylittyi.",
        lunch_limit_subceeded: "Lounasmaksun vähimmäisrajan tulee ylittyä.",
        timeout: "Voi harmi. Maksun aikaraja meni umpeen.",
        insufficient_funds:
          "Sinulla ei ole riittävästi etua maksun tekemiseen.",
        unauthorized: "Luvaton...",
      },
    },
    redirectMessage:
      "Siirrytään takaisin kauppaan. Odota hetki, älä sulje selainta.",
  },
  AppContent: {
    timeRemaining: "{time} aikaa jäljellä",
    navigationInstruction:
      "Odota rauhassa, sinut siirretään pian takaisin verkkokauppaan. Älä sulje selainta tai klikkaa takaisin-painiketta.",
  },
  AppFooter: {
    cancelText: "Peruuta",
    helpCenterText: "Asiakastuki",
  },
  VenueSearchFooter: {
    companyInfo: {
      companyName: "Smartum Oy",
      companyAddress: "Yrjönkatu 11 B",
      companyPostalCode: "00120 Helsinki",
      companyIdentifier: "Y-Tunnus: 2780101-9",
    },
    products: {
      sectionTitle: "Tuotteet",
      purchaseGiftcard: "Smartum Lahjakortin ostaminen",
      purchaseGiftcardLink: "https://kauppa.smartum.fi",
      useGiftcard: "Smartum Lahjakortin käyttäminen",
      useGiftcardLink: "https://pay.smartum.fi/",
      employeeBenefits: "Työsuhde-edut",
      employeeBenefitsLink: "https://www.smartum.fi/",
    },
    help: {
      heading: "Tarvitsetko apua?",
      faq: "Usein kysytyt kysymykset",
      faqLink: "https://kauppa.smartum.fi/pages/contact",
      helpCenter: "Help Center",
      helpCenterLink: "https://www.smartum.fi/knowledge/smartum-lahjakortti",
    },
    footer: {
      copyrightText: "© 2024 Smartum Oy",
      rightsReserved: "All rights reserved",
      privacyPolicy: "Tietosuojaselosteet",
      privacyPolicyLink: "https://www.smartum.fi/tietosuojaselosteet",
      cookies: "Evästeet",
      cookiesLink: "https://www.smartum.fi/evasteiden-kaytto",
      facebook: "https://fi-fi.facebook.com/smartumfi",
      instagram: "https://www.instagram.com/smartumfi/",
    }
  },

  ErrorPage: {
    paymentFailed: {
      title: "Maksu epäonnistui",
      message: "Pahoittelut, maksun vahvistamisessa tapahtui virhe.",
    }, 
    receiptDownloadFailed: {
      title: "Kuitin lataaminen epäonnistui..",
      message: "Pahoittelut, kuitin lataamisessa tapahtui virhe.",
    }, 
    pageNotFound: {
      title: "Sivua ei löytynyt...",
      message: "Sivua ei löytynyt tai jokin meni vikaan ladattaessa sivua. Palaa takaisin ",
      linkTitle: "kotisivulle.",
    },
  },
  GenericError: {
    general: {
      text: {
        heading: "Sivua ei löydy",
        explanation:
          "Tämä sivu on saattanut poistua tai olla väliaikaisesti pois käytöstä.",
      },
    },
    internalError: {
      text: {
        heading: "Oho! Jokin meni vikaan...",
        explanation:
          "Meillä on teknisiä ongelmia ja Smartumin huoltoväki tutkii asiaa.",
      },
    },
  },
  ApiError: {
    venue_parameter_invalid: "Käyttöpaikan tunniste on virheellinen.",
    venue_not_found: "Käyttöpaikkaa ei löydy.",
    account_id_invalid: "Etutilin tunniste on virheellinen.",
    incompatible_account_id: "Etutilin tunniste on virheellinen.",
    account_not_found: "Etutiliä ei löydy.",
    general: "Koita hetken kuluttua uudelleen.",
  },
};
