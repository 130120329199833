import React, { useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { getGiftcardAccountByClaimCode } from "../../../utils/api/api";
import { GiftcardAccount } from "../../../types/account";
import { useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  helperText: {
    maxWidth: "200px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "left",
    width: "100%",
  },
  input: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  button: {
    minWidth: "80px",
  },
}));

type GiftCardInputProps = {
  inputFields: {
    claimCode: string;
    setClaimCode: (value: string) => void;
  };
  setAccount: (account: GiftcardAccount | null) => void;
  giftcardValidationError: boolean;
  claimCodeError: string | null;
  setClaimCodeError: (value: string | null) => void;
  setMissingClaimCode: (value: string | null) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

export type GiftCardInputContainerHandle = {
  handleGetGiftcardAccount: () => void;
};

const GiftCardInputContainer = (props: GiftCardInputProps) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const {
    inputFields,
    setAccount,
    claimCodeError,
    setClaimCodeError,
    giftcardValidationError,
    setMissingClaimCode,
    loading,
    setLoading,
  } = props;

  const { claimCode, setClaimCode } = inputFields;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFn: (value: string) => void
  ) => {
    const value = event.target.value;
    setFn(value);
  };

  const handleGetGiftcardAccount = useCallback(async () => {
    if (!claimCode) {
      return;
    }
    setAccount(null);
    setLoading(true);
    setClaimCodeError(null);
    setMissingClaimCode(null);

    const { account, error } = await getGiftcardAccountByClaimCode(claimCode);

    if (error) {
      setClaimCodeError(
        intl.formatMessage({
          id: `GiftcardInput.error.${error}`,
          defaultMessage: intl.formatMessage({
            id: "GiftcardInput.error.generic",
          }),
        })
      );
    }
    if (account) {
      setAccount(account);
    }

    setLoading(false);
  }, [
    claimCode,
    intl,
    setAccount,
    setClaimCodeError,
    setMissingClaimCode,
    setLoading,
  ]);

  return (
    <Grid container direction="column">
      <Grid size={12}>
        <Box mt={4} />
        <Typography variant="subtitle2" color="textSecondary">
          {intl.formatMessage({
            id: "GiftcardInput.claimCode",
          })}
          :
        </Typography>
        <Box mt={2} />
        <FormControl
          error={!!claimCodeError || giftcardValidationError}
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            handleGetGiftcardAccount();
          }}
          className={classes.inputContainer}
        >
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid size={8}>
              <OutlinedInput
                error={!!claimCodeError || giftcardValidationError}
                placeholder="XXXXXXXXX"
                id="outlined-basic"
                value={claimCode}
                onChange={(e) => {
                  handleInputChange(e, setClaimCode);
                  setClaimCodeError(null);
                }}
                onBlur={handleGetGiftcardAccount}
                className={classes.input}
              />
              {claimCodeError && (
                <FormHelperText
                  id="component-error-text"
                  className={classes.helperText}
                >
                  {claimCodeError}
                </FormHelperText>
              )}
            </Grid>
            <Grid size={4}>
              <Button
                variant="contained"
                disabled={loading}
                onClick={handleGetGiftcardAccount}
                className={classes.button}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  intl.formatMessage({
                    id: "buttons.add",
                  })
                )}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Box m={1} />
    </Grid>
  );
};

GiftCardInputContainer.displayName = "GiftCardInputContainer";
export default GiftCardInputContainer;
